import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Events } from '../global/constants';

// ---------- Init Base Animation ----------

export const initBaseAnimations = () => {

	// ---------- Animations ----------

};

$Elements.document.on(Events.page.enter, () => initBaseAnimations());
